// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GTAG_ID ?? '';
const ADD_TO_CART = 'h36LCKG46LMDENbB9vcC';

declare global {
  interface Window {
    gtag: Gtag.Gtag;
  }
}

export const pageview = async (url: URL) => {
  const { analytics, logEvent } = await loadAnalytics();
  logEvent(analytics, 'page_view', {
    page_path: url.toString(),
  });
 
};

export const loadAnalytics = async () => {
  await import('lib/firebase.client');
  const firebase = await import('firebase/analytics');
  const analytics = firebase.getAnalytics();
  const logEvent = firebase.logEvent;
  return { analytics, logEvent };
};

export const gTagTracking = async () => {
  console.log(`Pushing tag now ${GA_TRACKING_ID}/${ADD_TO_CART}`);
  gtag('event', 'conversion', { 'send_to': `${GA_TRACKING_ID}/${ADD_TO_CART}`});
};
