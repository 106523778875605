import { pageview } from 'lib/analytics';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

export type AppWithErrorProps = AppProps & {
  err?: Error;
};

function MyApp({ Component, pageProps, err }: AppWithErrorProps) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  return (
    <>
      <Component {...pageProps} err={err} />
    </>
  );
}

export default MyApp;
